import { createEpic } from '../helpers';

import {
  getPublishGamesAsync,
  getPublishGamesDetailAsync,
  createPublishGamesAsync,
  updatePublishGamesAsync,
  deletePublishGamesAsync,
  getPublishGamePlayersAsync,
} from './publishGamesActions';

export const getPublishGamesEpic = createEpic(
  getPublishGamesAsync,
  'publishGames',
  'getList',
  true
);
export const getPublishGamesDetailEpic = createEpic(
  getPublishGamesDetailAsync,
  'publishGames',
  'detail',
  true
);
export const createPublishGamesEpic = createEpic(
  createPublishGamesAsync,
  'publishGames',
  'create',
  true
);
export const updatePublishGamesEpic = createEpic(
  updatePublishGamesAsync,
  'publishGames',
  'update',
  true
);
export const deletePublishGamesEpic = createEpic(
  deletePublishGamesAsync,
  'publishGames',
  'deleteGame',
  true
);
export const getPublishGamePlayersEpic = createEpic(
  getPublishGamePlayersAsync,
  'publishGames',
  'getPlayers',
  true
);
