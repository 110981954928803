import { createEpic } from '../helpers';
import {
  getDiscountAsync,
  getDiscountCodeAsync,
  getDiscountCodeLogAsync,
  getDiscountRewardedAsync,
  updateDiscountAsync,
  updateDiscountCodeAsync,
  updateDiscountRewardedAsync,
  createDiscountAsync,
  createDiscountCodeAsync,
  createDiscountRewardedAsync,
  getDiscountDetailAsync,
  getDiscountCodeDetailAsync,
  getDiscountRewardedDetailAsync,
  getDiscountListAsync,
  updateDiscountStatusAsync,
  deleteDiscountRewardedAsync,
  deleteDiscountCodeAsync,
} from './discountAction';

export const getDiscountEpic = createEpic(
  getDiscountAsync,
  'discount',
  'getList',
  true
);
export const getDiscountCodeEpic = createEpic(
  getDiscountCodeAsync,
  'discount',
  'getCodeList',
  true
);
export const getDiscountCodeLogEpic = createEpic(
  getDiscountCodeLogAsync,
  'discount',
  'getCodeLogList',
  true
);
export const getDiscountRewardedEpic = createEpic(
  getDiscountRewardedAsync,
  'discount',
  'getRewardedList',
  true
);
export const updateDiscountEpic = createEpic(
  updateDiscountAsync,
  'discount',
  'update',
  true
);
export const updateDiscountCodeEpic = createEpic(
  updateDiscountCodeAsync,
  'discount',
  'updateCode',
  true
);
export const updateDiscountRewardedEpic = createEpic(
  updateDiscountRewardedAsync,
  'discount',
  'updateReward',
  true
);
export const createDiscountEpic = createEpic(
  createDiscountAsync,
  'discount',
  'create',
  true
);
export const createDiscountCodeEpic = createEpic(
  createDiscountCodeAsync,
  'discount',
  'createCode',
  true
);
export const createDiscountRewardedEpic = createEpic(
  createDiscountRewardedAsync,
  'discount',
  'createReward',
  true
);
export const getDiscountDetailEpic = createEpic(
  getDiscountDetailAsync,
  'discount',
  'getDetail',
  true
);
export const getDiscountCodeDetailEpic = createEpic(
  getDiscountCodeDetailAsync,
  'discount',
  'getCodeDetail',
  true
);
export const getDiscountRewardedDetailEpic = createEpic(
  getDiscountRewardedDetailAsync,
  'discount',
  'getRewardDetail',
  true
);
export const getDiscountListEpic = createEpic(
  getDiscountListAsync,
  'discount',
  'getDiscounts',
  true
);
export const updateDiscountStatusEpic = createEpic(
  updateDiscountStatusAsync,
  'discount',
  'updateDiscountStatus',
  true
);
export const deleteDiscountRewardedEpic = createEpic(
  deleteDiscountRewardedAsync,
  'discount',
  'deleteReward',
  true
);
export const deleteDiscountCodeEpic = createEpic(
  deleteDiscountCodeAsync,
  'discount',
  'deleteCode',
  true
);
