import { api, download } from '../helpers';

export const getStatus = (params, token) =>
  api('/klik/stats', 'GET', undefined, undefined, token);

export const generate = (payload, token) =>
  api(`/klik/generate`, 'POST', payload, undefined, token);

export const downloadData = (params, token) =>
  download('/klik/export', params, token);
