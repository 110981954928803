import { combineActions, handleActions } from 'redux-actions';
import {
  createMiniBannerAsync,
  getMiniBannerAsync,
  uploadMiniBannerBackgroundImageAsync,
  uploadMiniBannerLogoAsync,
} from './miniBannerAction';

const initialState = {
  detail: {},
  fetch: {
    loading: false,
    error: '',
  },
  backgroundImageAction: {
    loading: false,
    success: false,
    error: '',
  },
  logoAction: {
    loading: false,
    success: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    error: '',
  },
};

export const miniBannerReducer = handleActions(
  new Map([
    [
      getMiniBannerAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      combineActions(createMiniBannerAsync.request),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(createMiniBannerAsync.success),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
          uploaded: false,
        },
      }),
    ],

    /// upload background image
    [
      combineActions(uploadMiniBannerBackgroundImageAsync.request),
      (state) => ({
        ...state,
        backgroundImageAction: {
          ...initialState.backgroundImageAction,
          loading: true,
        },
      }),
    ],
    [
      combineActions(uploadMiniBannerBackgroundImageAsync.success),
      (state, { payload }) => ({
        ...state,
        backgroundImageAction: {
          ...initialState.backgroundImageAction,
          success: payload,
        },
      }),
    ],
    [
      combineActions(uploadMiniBannerBackgroundImageAsync.failure),
      (state, { payload }) => ({
        ...state,
        backgroundImageAction: {
          ...initialState.backgroundImageAction,
          error: payload,
        },
      }),
    ],

    /// uploade logo
    [
      combineActions(uploadMiniBannerLogoAsync.request),
      (state) => ({
        ...state,
        logoAction: {
          ...initialState.logoAction,
          loading: true,
        },
      }),
    ],
    [
      combineActions(uploadMiniBannerLogoAsync.success),
      (state, { payload }) => ({
        ...state,
        logoAction: {
          ...initialState.logoAction,
          success: payload,
        },
      }),
    ],
    [
      combineActions(uploadMiniBannerLogoAsync.failure),
      (state, { payload }) => ({
        ...state,
        logoAction: {
          ...initialState.logoAction,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
