import { handleActions } from 'redux-actions';
import {
  getBlizzardStatusAsync,
  getBlizzardListAsync,
  importBlizzardCodeAsync,
} from './blizzardAction';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },

  status: {
    total: 0,
    sold: 0,
    remaining: 0,
    five_total: 0,
    five_sold: 0,
    five_remaining: 0,
    ten_total: 0,
    ten_sold: 0,
    ten_remaining: 0,
    twenty_total: 0,
    twenty_remaining: 0,
    twenty_sold: 0,
  },
  fetch: {
    loading: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    changed: null,
    uploaded: false,
    error: '',
  },
};
export const blizzardReducer = handleActions(
  new Map([
    [
      getBlizzardStatusAsync.success,
      (state, { payload }) => ({
        ...state,
        status: payload,
      }),
    ],
    [
      getBlizzardListAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      importBlizzardCodeAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      importBlizzardCodeAsync.success,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      importBlizzardCodeAsync.failure,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
