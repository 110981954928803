import { createAsyncAction } from '../helpers';
export const getStoreBannerAsync = createAsyncAction(
  'GET_STORE_BANNER'
);
export const createStoreBannerAsync = createAsyncAction(
  'CREATE_STORE_BANNER'
);
export const updateStoreBannerAsync = createAsyncAction(
  'UPDATE_STORE_BANNER'
);
export const deleteStoreBannerAsync = createAsyncAction(
  'DELETE_STORE_BANNER'
);
export const getStoreBannerDetailAsync = createAsyncAction(
  'GET_STORE_BANNER_DETAIL'
);

export const uploadStoreBannerImagesAsync = createAsyncAction(
  'UPLOAD_STORE_BANNER_IMAGES'
);
