import { api } from '../helpers';

export const getList = (params, token) =>
  api('/banners', 'GET', undefined, params, token);

export const createBanner = (payload, token) =>
  api('/banners', 'POST', payload, undefined, token);

export const updateBanner = ({ id, ...payload }, token) => {
  console.log('payload', payload);
  return api(
    `/banners/${id}`,
    'PUT',
    payload.payload,
    undefined,
    token
  );
};

export const getBannerDetail = (id, token) =>
  api(`/banners/${id}`, 'GET', undefined, undefined, token);

export const changeBannerStatus = ({ id, status }, token) =>
  api(
    `/banners/${id}/change-status`,
    'PUT',
    { status },
    undefined,
    token
  );

export const deleteBanner = (id, token) =>
  api(`/banners/${id}`, 'DELETE', undefined, undefined, token);

export const uploadBannerImage = ({ id, image }, token) => {
  const formData = new FormData();
  formData.append('image', image);

  return api(
    `/banners/${id}/bannerImage`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};
