import { api } from '../helpers';

export const getList = (params, token) =>
  api('/community-leader', 'GET', undefined, params, token);
export const getTournamentNames = (params, token) =>
  api(
    '/tournaments/tournament-names',
    'GET',
    undefined,
    undefined,
    token
  );
export const getCommunityLeaderDetail = (id, token) =>
  api(`/community-leader/${id}`, 'GET', undefined, undefined, token);
export const createCommunityLeader = (payload, token) =>
  api(
    '/community-leader/register',
    'POST',
    payload,
    undefined,
    token
  );
export const updateCommunityLeaderStatus = ({ id, status }, token) =>
  api(
    `/community-leader/${id}/change-status`,
    'PUT',
    { status },
    undefined,
    token
  );
export const updateCommunityLeader = ({ id, ...payload }, token) =>
  api(`/community-leader/${id}`, 'PUT', payload, undefined, token);
export const deleteCommunityLeader = (id, token) =>
  api(
    `/community-leader/${id}`,
    'DELETE',
    undefined,
    undefined,
    token
  );
