import { combineActions, handleActions } from 'redux-actions';
import {
  createStoreAsync,
  getAllStoreAsync,
  getStoreAsync,
  getStoreCategoriesAsync,
  getStoreItemsAsync,
  getStoreDetailAsync,
  updateStoreAsync,
  deleteStoreAsync,
  getDeveloperGamesAsync,
} from './storesAction';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  developers: [],
  categories: [],
  items: [],
  stores: [],
  detail: {},
  fetch: {
    loading: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    changed: null,
    uploaded: false,
    error: '',
  },
};
export const storeReducer = handleActions(
  new Map([
    [
      getDeveloperGamesAsync.success,
      (state, { payload }) => ({
        ...state,
        developers: payload,
      }),
    ],
    [
      getAllStoreAsync.success,
      (state, { payload }) => ({
        ...state,
        stores: payload,
      }),
    ],
    [
      getStoreCategoriesAsync.success,
      (state, { payload }) => ({
        ...state,
        categories: payload,
      }),
    ],
    [
      getStoreItemsAsync.success,
      (state, { payload }) => ({
        ...state,
        items: payload,
      }),
    ],
    [
      getStoreAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      getStoreDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      deleteStoreAsync.success,
      (state, { payload }) => ({
        ...state,
        changed: payload,
      }),
    ],
    [
      combineActions(
        createStoreAsync.request,
        updateStoreAsync.request,
        deleteStoreAsync.request
      ),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        createStoreAsync.success,
        updateStoreAsync.success,
        deleteStoreAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      combineActions(
        createStoreAsync.failure,
        updateStoreAsync.failure,
        deleteStoreAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
