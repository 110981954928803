import { createEpic } from '../helpers';
import {
  getDownloadAsync,
  createDownloadAsync,
  updateDownloadAsync,
  deleteDownloadAsync,
  getDownloadDetailAsync,
  getDownloadCategoriesAsync,
  uploadDownloadLogoAsync,
  uploadDownloadMediaAsync,
} from './downloadAction';

export const getDownloadEpic = createEpic(
  getDownloadAsync,
  'download',
  'getList',
  true
);
export const createDownloadEpic = createEpic(
  createDownloadAsync,
  'download',
  'create',
  true
);
export const updateDownloadEpic = createEpic(
  updateDownloadAsync,
  'download',
  'update',
  true
);
export const deleteDownloadEpic = createEpic(
  deleteDownloadAsync,
  'download',
  'deleteDownload',
  true
);
export const getDownloadDetailEpic = createEpic(
  getDownloadDetailAsync,
  'download',
  'detail',
  true
);
export const getDownloadCategoriesEpic = createEpic(
  getDownloadCategoriesAsync,
  'download',
  'getCategories',
  true
);
export const uploadDownloadLogoEpic = createEpic(
  uploadDownloadLogoAsync,
  'download',
  'logoUpload',
  true
);
export const uploadDownloadMediaEpic = createEpic(
  uploadDownloadMediaAsync,
  'download',
  'mediaUpload',
  true
);
