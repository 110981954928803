import { createAsyncAction } from '../helpers';
export const getMiniBannerAsync = createAsyncAction(
  'GET_MINI_BANNER'
);
export const createMiniBannerAsync = createAsyncAction(
  'CREATE_MINI_BANNER'
);
export const uploadMiniBannerBackgroundImageAsync = createAsyncAction(
  'UPDATE_BACKGROUND_IMAGE'
);
export const uploadMiniBannerLogoAsync = createAsyncAction(
  'UPDATE_LOGO'
);
