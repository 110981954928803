import { combineActions, handleActions } from 'redux-actions';
import {
  getExchangesAsync,
  refreshExchangesAsync,
  updateExchangesAsync,
} from './exchangesAction';

const initialState = {
  list: [],
  action: {
    loading: false,
    success: false,
    changed: null,
    refresh: false,
    error: '',
  },
};
export const exchangesReducer = handleActions(
  new Map([
    [
      getExchangesAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      refreshExchangesAsync.request,
      (state, { payload }) => ({
        ...state,
        action: {
          ...state.action,
          error: '',
          refresh: true,
        },
      }),
    ],
    [
      refreshExchangesAsync.failure,
      (state, { payload }) => ({
        ...state,
        action: {
          ...state.action,
          error: payload,
          refresh: false,
        },
      }),
    ],
    [
      refreshExchangesAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
        action: {
          ...state.action,
          error: '',
          refresh: false,
        },
      }),
    ],
    [
      combineActions(updateExchangesAsync.request),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(updateExchangesAsync.success),
      (state, payload) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: false,
          success: payload,
        },
      }),
    ],
    [
      combineActions(updateExchangesAsync.failure),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
