import { createDownloadAsCSVEpic, createEpic } from '../helpers';

import {
  getKlikStatusAsync,
  generateKlikCodeAsync,
  downloadKlikCodeAsync,
} from './kliksAction';

export const getKlikStatusEpic = createEpic(
  getKlikStatusAsync,
  'kliks',
  'getStatus',
  true
);

export const generateKlikCodeEpic = createEpic(
  generateKlikCodeAsync,
  'kliks',
  'generate',
  true
);

export const downloadKlikCodeEpic = createDownloadAsCSVEpic(
  downloadKlikCodeAsync,
  'kliks',
  'downloadData',
  true,
  'klik-indomaret-codes'
);
