import { combineActions, handleActions } from 'redux-actions';
import {
  getDiscountAsync,
  getDiscountCodeAsync,
  getDiscountCodeLogAsync,
  getDiscountRewardedAsync,
  updateDiscountAsync,
  updateDiscountCodeAsync,
  updateDiscountRewardedAsync,
  createDiscountAsync,
  createDiscountCodeAsync,
  createDiscountRewardedAsync,
  getDiscountDetailAsync,
  getDiscountCodeDetailAsync,
  getDiscountRewardedDetailAsync,
  getDiscountListAsync,
  updateDiscountStatusAsync,
  deleteDiscountRewardedAsync,
  deleteDiscountCodeAsync,
} from './discountAction';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  codes: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  rewards: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  logs: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  discounts: [],
  detail: {},
  action: {
    loading: false,
    success: false,
    error: '',
    changed: false,
  },
};

export const discountReducer = handleActions(
  new Map([
    // Getter
    [
      getDiscountAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      getDiscountCodeAsync.success,
      (state, { payload }) => ({
        ...state,
        codes: payload,
      }),
    ],
    [
      getDiscountCodeLogAsync.success,
      (state, { payload }) => ({
        ...state,
        logs: payload,
      }),
    ],
    [
      getDiscountRewardedAsync.success,
      (state, { payload }) => ({
        ...state,
        rewards: payload,
      }),
    ],
    [
      getDiscountListAsync.success,
      (state, { payload }) => ({
        ...state,
        discounts: payload,
      }),
    ],

    [
      combineActions(
        getDiscountDetailAsync.success,
        getDiscountCodeDetailAsync.success,
        getDiscountRewardedDetailAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      combineActions(
        createDiscountAsync.failure,
        createDiscountCodeAsync.failure,
        createDiscountRewardedAsync.failure,
        updateDiscountAsync.failure,
        updateDiscountCodeAsync.failure,
        updateDiscountRewardedAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],

    [
      combineActions(
        createDiscountAsync.request,
        createDiscountCodeAsync.request,
        createDiscountRewardedAsync.request,
        updateDiscountAsync.request,
        updateDiscountCodeAsync.request,
        updateDiscountRewardedAsync.request,
        deleteDiscountRewardedAsync.request,
        deleteDiscountCodeAsync.request
      ),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        createDiscountAsync.success,
        createDiscountCodeAsync.success,
        createDiscountRewardedAsync.success,
        updateDiscountAsync.success,
        updateDiscountCodeAsync.success,
        updateDiscountRewardedAsync.success,
        deleteDiscountRewardedAsync.success,
        deleteDiscountCodeAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      combineActions(
        createDiscountAsync.failure,
        createDiscountCodeAsync.failure,
        createDiscountRewardedAsync.failure,
        updateDiscountAsync.failure,
        updateDiscountCodeAsync.failure,
        updateDiscountRewardedAsync.failure,
        updateDiscountStatusAsync.failure,
        deleteDiscountRewardedAsync.failure,
        deleteDiscountCodeAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],
    [
      combineActions(updateDiscountStatusAsync.success),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          changed: payload,
        },
      }),
    ],
  ]),
  initialState
);
