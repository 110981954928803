import { createEpic } from '../helpers';
import {
  getDevelopersAsync,
  createDeveloperAsync,
  changeDeveloperStatusAsync,
  deleteDeveloperAsync,
  generateApiKeyAsync,
  resendDeveloperAsync,
  getSubmissionAsync,
  sendSubmissionAsync,
  getSubmissionDetailAsync,
  getStoreSubmissionAsync,
  getStoreSubmissionDetailAsync,
  sendStoreSubmissionAsync,
  getDeveloperDetailAsync,
  sendSubmissionUnderReviewAsync,
  sendStoreSubmissionUnderReviewAsync,
  getItemSubmissionAsync,
  getItemSubmissionDetailAsync,
  sendItemSubmissionAsync,
} from './developersActions';
export const getDevelopersEpic = createEpic(
  getDevelopersAsync,
  'developers',
  'getList',
  true
);
export const createDeveloperEpic = createEpic(
  createDeveloperAsync,
  'developers',
  'createDeveloper',
  true
);
export const changeDeveloperStatusEpic = createEpic(
  changeDeveloperStatusAsync,
  'developers',
  'changeStatus',
  true
);
export const deleteDeveloperEpic = createEpic(
  deleteDeveloperAsync,
  'developers',
  'deleteDeveloper',
  true
);
export const generateApiKeyEpic = createEpic(
  generateApiKeyAsync,
  'developers',
  'generateApiKey',
  true
);
export const resendDeveloperEpic = createEpic(
  resendDeveloperAsync,
  'developers',
  'resendDeveloper',
  true
);
export const getSubmissionEpic = createEpic(
  getSubmissionAsync,
  'developers',
  'getSubmission',
  true
);
export const sendSubmissionEpic = createEpic(
  sendSubmissionAsync,
  'developers',
  'sendSubmission',
  true
);
export const sendSubmissionUnderReviewEpic = createEpic(
  sendSubmissionUnderReviewAsync,
  'developers',
  'sendSubmissionUnderReview',
  true
);
export const getSubmissionDetailEpic = createEpic(
  getSubmissionDetailAsync,
  'developers',
  'getSubmissionDetail',
  true
);
export const getStoreSubmissionEpic = createEpic(
  getStoreSubmissionAsync,
  'developers',
  'getStoreSubmission',
  true
);
export const getStoreSubmissionDetailEpic = createEpic(
  getStoreSubmissionDetailAsync,
  'developers',
  'getStoreSubmissionDetail',
  true
);
export const getDeveloperDetailEpic = createEpic(
  getDeveloperDetailAsync,
  'developers',
  'getDetail',
  true
);
export const sendStoreSubmissionEpic = createEpic(
  sendStoreSubmissionAsync,
  'developers',
  'sendStoreSubmission',
  true
);
export const sendStoreSubmissionUnderReviewEpic = createEpic(
  sendStoreSubmissionUnderReviewAsync,
  'developers',
  'sendStoreSubmissionUnderReview',
  true
);
export const getItemSubmissionEpic = createEpic(
  getItemSubmissionAsync,
  'developers',
  'getItemSubmission',
  true
);
export const getItemsSubmissionDetailEpic = createEpic(
  getItemSubmissionDetailAsync,
  'developers',
  'getItemSubmissionDetail',
  true
);
export const sendItemSubmissionEpic = createEpic(
  sendItemSubmissionAsync,
  'developers',
  'sendItemSubmission',
  true
);
