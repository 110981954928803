import { createAsyncAction } from '../helpers';
export const getStoreAsync = createAsyncAction('GET_STORE');
export const getAllStoreAsync = createAsyncAction('GET_ALL_STORE');
export const createStoreAsync = createAsyncAction('CREATE_STORE');
export const updateStoreAsync = createAsyncAction('UPDATE_STORE');
export const deleteStoreAsync = createAsyncAction('DELETE_STORE');
export const getStoreDetailAsync = createAsyncAction(
  'GET_STORE_DETAIL'
);
export const getDeveloperGamesAsync = createAsyncAction(
  'GET_DEVELOPER_GAMES'
);
export const getStoreItemsAsync = createAsyncAction(
  'GET_STORE_ITEMS'
);
export const getStoreCategoriesAsync = createAsyncAction(
  'GET_STORE_CATEGORIES'
);
export const uploadStoreImagesAsync = createAsyncAction(
  'UPLOAD_STORE_IMAGES'
);
