import { createEpic } from '../helpers';
import {
  createMiniBannerAsync,
  getMiniBannerAsync,
  uploadMiniBannerBackgroundImageAsync,
  uploadMiniBannerLogoAsync,
} from './miniBannerAction';

export const getMiniBannerEpic = createEpic(
  getMiniBannerAsync,
  'miniBanner',
  'getMiniBanner',
  true
);
export const createMiniBannerEpic = createEpic(
  createMiniBannerAsync,
  'miniBanner',
  'createMiniBanner',
  true
);
export const updateBackgroundImageEpic = createEpic(
  uploadMiniBannerBackgroundImageAsync,
  'miniBanner',
  'updateMiniBannerBackgroundImage',
  true
);
export const updateLogoEpic = createEpic(
  uploadMiniBannerLogoAsync,
  'miniBanner',
  'updateMiniBannerLogo',
  true
);
