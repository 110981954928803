import { handleActions } from 'redux-actions';
import {
  getDashboardChartsAsync,
  getDashboardStatsAsync,
} from './dashboardActions';

const initialState = {
  stats: {},
  charts: {
    userAndTourData: {
      userCounts: [],
      tournamentCounts: [],
      labels: [],
    },
  },
  fetch: {
    loading: false,
    error: '',
  },

  action: {
    loading: false,
    success: false,
    error: '',
  },
};

export const dashboardReducer = handleActions(
  new Map([
    [
      getDashboardStatsAsync.success,
      (state, { payload }) => ({
        ...state,
        stats: payload,
      }),
    ],
    [
      getDashboardChartsAsync.success,
      (state, { payload }) => ({
        ...state,
        charts: {
          ...initialState.charts,
          userAndTourData: payload.userAndTourData,
        },
      }),
    ],
  ]),
  initialState
);
