import { createEpic } from '../helpers';
import {
  getDashboardStatsAsync,
  getDashboardChartsAsync,
} from './dashboardActions';

export const getDashboardStatsEpic = createEpic(
  getDashboardStatsAsync,
  'dashboard',
  'getDashboardStats',
  true
);

export const getDashboardChartsEpic = createEpic(
  getDashboardChartsAsync,
  'dashboard',
  'getDashboardCharts',
  true
);
