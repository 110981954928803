import { createEpic } from '../helpers';
import {
  createCommunityLeaderAsync,
  deleteCommunityLeaderAsync,
  getCommunityLeaderDetailAsync,
  getCommunityLeadersAsync,
  getTournamentNamesAsync,
  updateCommunityLeaderAsync,
  updateCommunityLeaderStatusAsync,
} from './communityLeadersActions';

export const getCommunityLeadersEpic = createEpic(
  getCommunityLeadersAsync,
  'communityLeaders',
  'getList',
  true
);
export const getTournamentNamesEpic = createEpic(
  getTournamentNamesAsync,
  'communityLeaders',
  'getTournamentNames',
  true
);
export const getCommunityLeaderDetailEpic = createEpic(
  getCommunityLeaderDetailAsync,
  'communityLeaders',
  'getCommunityLeaderDetail',
  true
);
export const createCommunityLeaderEpic = createEpic(
  createCommunityLeaderAsync,
  'communityLeaders',
  'createCommunityLeader',
  true
);
export const updateCommunityLeaderStatusEpic = createEpic(
  updateCommunityLeaderStatusAsync,
  'communityLeaders',
  'updateCommunityLeaderStatus',
  true
);
export const updateCommunityLeaderEpic = createEpic(
  updateCommunityLeaderAsync,
  'communityLeaders',
  'updateCommunityLeader',
  true
);
export const deleteCommunityLeaderEpic = createEpic(
  deleteCommunityLeaderAsync,
  'communityLeaders',
  'deleteCommunityLeader',
  true
);
