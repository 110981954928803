import { combineActions, handleActions } from 'redux-actions';
import {
  createCommunityLeaderAsync,
  getCommunityLeadersAsync,
  updateCommunityLeaderStatusAsync,
  deleteCommunityLeaderAsync,
  getTournamentNamesAsync,
  getCommunityLeaderDetailAsync,
  updateCommunityLeaderAsync,
} from './communityLeadersActions';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },

  tournaments: [],

  detail: {},

  action: {
    loading: false,
    success: false,
    changed: null,
    error: '',
  },
};

export const communityLeaderReducer = handleActions(
  new Map([
    [
      getCommunityLeadersAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],

    [
      createCommunityLeaderAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      createCommunityLeaderAsync.success,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          success: true,
        },
      }),
    ],
    [
      createCommunityLeaderAsync.failure,
      (state, payload) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],

    [
      getTournamentNamesAsync.success,
      (state, { payload }) => ({
        ...state,
        tournaments: payload,
      }),
    ],

    [
      getCommunityLeaderDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],

    [
      combineActions(
        updateCommunityLeaderAsync.request,
        updateCommunityLeaderStatusAsync.request,
        deleteCommunityLeaderAsync.request
      ),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        updateCommunityLeaderAsync.success,
        updateCommunityLeaderStatusAsync.success,
        deleteCommunityLeaderAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: false,
          changed: payload,
        },
      }),
    ],
    [
      combineActions(
        updateCommunityLeaderAsync.failure,
        updateCommunityLeaderStatusAsync.failure,
        deleteCommunityLeaderAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
