import { createDownloadEpic, createEpic } from '../helpers';
import {
  getPlayersAsync,
  getPlayerDetailAsync,
  getJoinedParticipantsAsync,
  updatePlayerAsync,
  changePlayerStatusAsync,
  uploadAvatarAsync,
  getAuthAccountsAsync,
  uploadIdCardUrlAsync,
  changeForumTitleAsync,
  changeForumRoleAsync,
  downloadPlayersAsync,
} from './playersActions';

export const getListEpic = createEpic(
  getPlayersAsync,
  'players',
  'getList',
  true
);

export const getDetilEpic = createEpic(
  getPlayerDetailAsync,
  'players',
  'getDetail',
  true
);

export const updatePlayerEpic = createEpic(
  updatePlayerAsync,
  'players',
  'updatePlayer',
  true
);

export const changePlayerStatus = createEpic(
  changePlayerStatusAsync,
  'players',
  'changePlayerStatus',
  true
);

export const getJoinedParticipantsEpic = createEpic(
  getJoinedParticipantsAsync,
  'players',
  'getJoinedParticipants',
  true
);

export const uploadAvatarEpic = createEpic(
  uploadAvatarAsync,
  'players',
  'uploadAvatar',
  true
);
export const uploadIDCardEpic = createEpic(
  uploadIdCardUrlAsync,
  'players',
  'uploadAvatar',
  true
);

export const getAuthAccountsEpic = createEpic(
  getAuthAccountsAsync,
  'players',
  'getAuthAccounts',
  true
);

export const changeForumTitle = createEpic(
  changeForumTitleAsync,
  'players',
  'changeForumTitle',
  true
);

export const changeForumRole = createEpic(
  changeForumRoleAsync,
  'players',
  'changeForumRole',
  true
);

export const downloadTournaments = createDownloadEpic(
  downloadPlayersAsync,
  'players',
  'exportPlayers',
  true,
  'playersData'
);
