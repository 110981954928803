import { createAsyncAction } from '../helpers';
export const getBlizzardStatusAsync = createAsyncAction(
  'GET_BLIZZARD_STATUS'
);
export const getBlizzardListAsync = createAsyncAction(
  'GET_BLIZZARD_LIST'
);
export const importBlizzardCodeAsync = createAsyncAction(
  'IMPORT_BLIZZARD_CODE'
);
export const exportBlizzardCodeAsync = createAsyncAction(
  'EXPORT_BLIZZARD_CODE'
);
export const exportBlizzardReportAsync = createAsyncAction(
  'EXPORT_BLIZZARD_REPORT'
);
