import { createAsyncAction } from '../helpers';

export const getPublishGamesAsync = createAsyncAction(
  'GET_PUBLISH_GAMES'
);
export const getPublishGamesDetailAsync = createAsyncAction(
  'GET_PUBLISH_GAME_DETAIL'
);
export const createPublishGamesAsync = createAsyncAction(
  'CREATE_PUBLISH_GAME'
);
export const updatePublishGamesAsync = createAsyncAction(
  'UPDATE_PUBLISH_GAME'
);
export const deletePublishGamesAsync = createAsyncAction(
  'DELETE_PUBLISH_GAME'
);
export const getPublishGamePlayersAsync = createAsyncAction(
  'GET_PUBLISH_GAME_PLAYERS'
);
