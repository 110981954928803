import { api } from '../helpers';

export const getList = (params) =>
  api('/store-games/banner', 'GET', undefined, params);

export const create = (payload, token) =>
  api('/store-games/banner', 'POST', payload, undefined, token);

export const update = ({ id, ...payload }, token) =>
  api(`/store-games/banner/${id}`, 'PUT', payload, undefined, token);

export const detail = (id, token) =>
  api(
    `/store-games/banner/${id}`,
    'GET',
    undefined,
    undefined,
    token
  );

export const imageUpload = ({ id, image, thumbnail }, token) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('thumbnail', thumbnail);
  return api(
    `/store-games/banner/${id}/image`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};

export const deleteStoreBanner = (id, token) =>
  api(
    '/store-games/banner/' + id,
    'DELETE',
    undefined,
    undefined,
    token
  );
