import { handleActions, combineActions } from 'redux-actions';
import {
  getPublishGamesAsync,
  getPublishGamesDetailAsync,
  createPublishGamesAsync,
  updatePublishGamesAsync,
  deletePublishGamesAsync,
  getPublishGamePlayersAsync,
} from './publishGamesActions';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },

  players: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },

  detail: {},

  fetch: {
    loading: false,
    error: '',
  },

  action: {
    loading: false,
    success: false,
    error: '',
  },
};

export const publishGamesReducer = handleActions(
  new Map([
    [
      getPublishGamesAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      getPublishGamesDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      getPublishGamePlayersAsync.success,
      (state, { payload }) => ({
        ...state,
        players: payload,
      }),
    ],
    [
      combineActions(
        createPublishGamesAsync.success,
        updatePublishGamesAsync.success,
        deletePublishGamesAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
  ]),
  initialState
);
