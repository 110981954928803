import { api, download } from '../helpers';

export const getList = (params, token) =>
  api('/tournaments', 'GET', undefined, params, token);

export const getDetail = (id, token) =>
  api(`/tournaments/${id}`, 'GET', undefined, undefined, token);

export const getFeatureTournaments = (token) =>
  api(`/tournaments/featured`, 'GET', undefined, undefined, token);

export const getCountries = (params, token) =>
  api('/tournaments/countries', 'GET', undefined, undefined, token);

export const create = (payload, token) =>
  api(`/tournaments`, 'POST', payload, undefined, token);

export const update = ({ tournamentId, ...payload }, token) =>
  api(
    `/tournaments/${tournamentId}`,
    'PUT',
    payload,
    undefined,
    token
  );

export const uploadCoverImage = ({ id, image }, token) => {
  const formData = new FormData();
  formData.append('image', image);
  return api(
    `/tournaments/${id}/coverImage`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};

export const uploadRulesPDF = ({ id, pdf }, token) => {
  const formData = new FormData();
  formData.append('pdf', pdf);
  return api(
    `/tournaments/${id}/rulesPdf`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};

export const uploadFeaturedImages = ({ id, images }, token) => {
  const formData = new FormData();
  images.forEach((image) => {
    formData.append('images', image);
  });

  return api(
    `/tournaments/${id}/featuredImages`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};

export const changeStatus = ({ id, status }, token) =>
  api(
    `/tournaments/${id}/status`,
    'PUT',
    { status },
    undefined,
    token
  );

export const sendReminder = ({ id, teamId }, token) =>
  api(
    `/tournaments/${id}/team/${teamId}/reminder`,
    'POST',
    undefined,
    undefined,
    token
  );

export const changeFeatured = ({ id, isFeatured }, token) =>
  api(
    `/tournaments/${id}/featured`,
    'PUT',
    { isFeatured },
    undefined,
    token
  );

export const removeParticipant = (id, token) =>
  api(`/participants/${id}`, 'DELETE', undefined, undefined, token);

export const updateParticipantStatus = (
  { id, status, tournamentId },
  token
) =>
  api(
    `/tournaments/${tournamentId}/participants/${id}/status`,
    'PUT',
    { status },
    undefined,
    token
  );

export const updateTeamStatus = (
  { id, status, tournamentId },
  token
) =>
  api(
    `/tournaments/${tournamentId}/teams/${id}/status`,
    'PUT',
    { status },
    undefined,
    token
  );

export const removeTeamMember = ({ memberId, teamId }, token) =>
  api(
    `/teams/${teamId}/member/${memberId}`,
    'DELETE',
    undefined,
    undefined,
    token
  );
export const removeTeam = (id, token) =>
  api(`/teams/${id}`, 'DELETE', undefined, undefined, token);

export const updateParticipantResult = ({ id, placements }, token) =>
  api(
    `/tournaments/${id}/single/placement`,
    'PUT',
    { placements },
    undefined,
    token
  );

export const updateTeamResult = ({ id, placements }, token) =>
  api(
    `/tournaments/${id}/team/placement`,
    'PUT',
    { placements },
    undefined,
    token
  );

export const getTournamentParticipants = (query, token) =>
  api(`/participants`, 'GET', undefined, query, token);

export const getTournamentTeams = (query, token) =>
  api(`/teams`, 'GET', undefined, query, token);

export const getTournamentForBanners = (query, token) =>
  api(`/tournaments/banners`, 'GET', undefined, query, token);

export const getTournamentJoinedStats = ({ id }, token) =>
  api(
    `/tournaments/${id}/joinedStats`,
    'GET',
    undefined,
    undefined,
    token
  );

export const getAllTeams = ({ id }, token) =>
  api(`/teams/all-teams/${id}`, 'GET', undefined, undefined, token);

export const exportTournaments = (params, token) =>
  download('/tournaments/export', params, token);

export const exportTournamentsStats = (params, token) =>
  download('/tournaments/export/stats', params, token);

export const exportUpcomingSingleTournaments = (params, token) =>
  download('/participants/export', params, token);

export const exportUpcomingTeamTournaments = (params, token) =>
  download('/teams/export', params, token);

export const downloadJoinedTeamImages = (id, token) =>
  download(`/team/${id}/images_download`, undefined, token);

export const createBracket = ({ tournamentId, ...payload }, token) =>
  api(
    `/tournaments/${tournamentId}/bracket`,
    'POST',
    payload,
    undefined,
    token
  );

export const getBracketList = (tournamentId, token) =>
  api(
    `/tournaments/${tournamentId}/bracket`,
    'GET',
    undefined,
    undefined,
    token
  );

export const getBracketDetail = (
  { tournamentId, bracketId },
  token
) =>
  api(
    `/tournaments/${tournamentId}/bracket/${bracketId}`,
    'GET',
    undefined,
    undefined,
    token
  );

export const updateBracket = (
  { tournamentId, bracketId, ...payload },
  token
) =>
  api(
    `/tournaments/${tournamentId}/bracket/${bracketId}`,
    'PUT',
    { tournamentId, ...payload },
    undefined,
    token
  );

export const deleteBracket = ({ tournamentId, bracketId }, token) =>
  api(
    `/tournaments/${tournamentId}/bracket/${bracketId}`,
    'DELETE',
    undefined,
    undefined,
    token
  );

export const seedBracketTeam = (
  { tournamentId, bracketId, seeds },
  token
) =>
  api(
    `/tournaments/${tournamentId}/bracket/${bracketId}/seed`,
    'POST',
    { seeds },
    undefined,
    token
  );

export const updateTeamMemberInGameName = (
  { teamId, userId, inGameName },
  token
) =>
  api(
    `/teams/${teamId}/member/${userId}`,
    'PUT',
    { inGameName },
    undefined,
    token
  );

export const updateTeamName = ({ teamId, teamName }, token) =>
  api(`/teams/${teamId}/name`, 'PUT', { teamName }, undefined, token);

export const getTournamentTeamList = (
  { tournamentId, checkedIn, notSeeded },
  token
) =>
  api(
    `/tournaments/${tournamentId}/teams`,
    'GET',
    undefined,
    { checkedIn, notSeeded },
    token
  );

export const getTournamentParticipantList = (
  { tournamentId, checkedIn, notSeeded },
  token
) =>
  api(
    `/tournaments/${tournamentId}/participants`,
    'GET',
    undefined,
    { checkedIn, notSeeded },
    token
  );

export const updateParticipantInGameName = (
  { id, inGameName },
  token
) =>
  api(
    `/participants/${id}/in-game-name`,
    'PUT',
    { inGameName },
    undefined,
    token
  );

export const updateMatchScore = (
  {
    tournamentId,
    matchId,
    opponent1Score,
    opponent2Score,
    opponent1Forfeit,
    opponent2Forfeit,
  },
  token
) =>
  api(
    `/tournaments/${tournamentId}/match/${matchId}/score`,
    'POST',
    {
      opponent1Score,
      opponent2Score,
      opponent1Forfeit,
      opponent2Forfeit,
    },
    undefined,
    token
  );

export const tournamentCheckIn = (
  { tournamentId, status, participantId, format },
  token
) =>
  api(
    `/tournaments/${tournamentId}/check-in-by-admin`,
    'PUT',
    { status, participantId, format },
    undefined,
    token
  );

export const startBracket = ({ tournamentId, bracketId }, token) =>
  api(
    `/tournaments/${tournamentId}/bracket/${bracketId}/start`,
    'GET',
    undefined,
    undefined,
    token
  );

export const getMatchIssues = (
  { tournamentId, ...params },
  token
) => {
  console.log('params', params);
  return api(
    `/tournaments/${tournamentId}/report`,
    'GET',
    undefined,
    params,
    token
  );
};

export const updateMatchIssueStatus = (
  { tournamentId, reportId, isResolved },
  token
) =>
  api(
    `/tournaments/${tournamentId}/report/${reportId}`,
    'POST',
    { isResolved },
    undefined,
    token
  );

export const getMatchDetail = ({ tournamentId, matchId }, token) =>
  api(
    `/tournaments/${tournamentId}/match/${matchId}`,
    'GET',
    undefined,
    undefined,
    token
  );

export const getStandingDetail = (
  { tournamentId, roundId, bracketParticipantId },
  token
) =>
  api(
    `/tournaments/${tournamentId}/round/${roundId}/bracket-participant/${bracketParticipantId}`,
    'GET',
    undefined,
    undefined,
    token
  );

export const submitFFABracketScore = (
  { tournamentId, roundId, bracketParticipantId, ...payload },
  token
) =>
  api(
    `/tournaments/${tournamentId}/round/${roundId}/bracket-participant/${bracketParticipantId}`,
    'POST',
    payload,
    undefined,
    token
  );

export const uploadFFABracketScreenshot = (
  { tournamentId, roundId, bracketParticipantId, image },
  token
) => {
  const formData = new FormData();
  formData.append('image', image);
  return api(
    `/tournaments/${tournamentId}/round/${roundId}/bracket-participant/${bracketParticipantId}/screenshot`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};

export const submitForfeitPlayer = (
  { tournamentId, matchId, ...payload },
  token
) =>
  api(
    `/tournaments/${tournamentId}/match/${matchId}/forfeit`,
    'POST',
    payload,
    undefined,
    token
  );

export const submitDoubleLoss = ({ tournamentId, matchId }, token) =>
  api(
    `/tournaments/${tournamentId}/match/${matchId}/double-loss`,
    'POST',
    undefined,
    undefined,
    token
  );

export const submitDraw = ({ tournamentId, matchId }, token) =>
  api(
    `/tournaments/${tournamentId}/match/${matchId}/draw`,
    'POST',
    undefined,
    undefined,
    token
  );

export const submitDropParticipant = (
  { tournamentId, bracketId, ...payload },
  token
) =>
  api(
    `/tournaments/${tournamentId}/bracket/${bracketId}/drop`,
    'POST',
    payload,
    undefined,
    token
  );

export const generateNextSwissRound = (
  { tournamentId, matchId, ...payload },
  token
) =>
  api(
    `/tournaments/${tournamentId}/match/${matchId}/generate-next-swiss-round`,
    'POST',
    payload,
    undefined,
    token
  );

export const revertForfeit = ({ tournamentId, matchId }, token) =>
  api(
    `/tournaments/${tournamentId}/match/${matchId}/reset-score`,
    'PUT',
    undefined,
    undefined,
    token
  );
