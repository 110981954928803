import { api } from '../helpers';

export const getCategories = (params, token) =>
  api('/categories', 'GET', undefined, params, token);

export const createCategory = (payload, token) =>
  api('/categories', 'POST', payload, undefined, token);

export const deleteCategory = (id, token) =>
  api('/categories/' + id, 'DELETE', undefined, undefined, token);

export const createArticle = (payload, token) =>
  api('/articles', 'POST', payload, undefined, token);

export const updateArticle = ({ id, ...payload }, token) =>
  api('/articles/' + id, 'PUT', payload, undefined, token);

export const getArticles = (params, token) =>
  api('/articles', 'GET', undefined, params, token);

export const getArticle = (id, token) =>
  api('/articles/' + id, 'GET', undefined, undefined, token);

export const changeStatus = ({ id, status }, token) =>
  api(`/articles/${id}/status`, 'PUT', { status }, undefined, token);

export const getFeaturedArticles = (payload, token) =>
  api(`/articles/featured`, 'GET', undefined, undefined, token);

export const changeFeatured = ({ id, isFeatured }, token) =>
  api(
    `/articles/${id}/featured`,
    'PUT',
    { featured: isFeatured },
    undefined,
    token
  );

export const uploadImage = ({ id, image }, token) => {
  const formData = new FormData();
  formData.append('image', image);

  return api(
    '/articles/' + id + '/cover_image',
    'POST',
    formData,
    undefined,
    token,
    ''
  );
};
