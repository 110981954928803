import { handleActions } from 'redux-actions';
import { REHYDRATE, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  authLoginAsyncAction,
  authLogoutAction,
} from './authActions';

const initialState = {
  authenticated: true,
  token: '',
  profile: {},
  login: {
    loading: false,
    error: '',
  },
};

export const authReducer = persistReducer(
  {
    key: 'auth',
    storage,
    whitelist: ['token'],
  },
  handleActions(
    new Map([
      [
        REHYDRATE,
        (state, action) => ({
          ...state,
          authenticated:
            action.payload && action.payload.token ? true : false,
        }),
      ],
      [
        authLoginAsyncAction.request,
        (state) => ({
          ...state,
          login: { ...initialState.login },
        }),
      ],
      [
        authLoginAsyncAction.success,
        (state, { payload }) => ({
          ...state,
          token: payload.accessToken,
          authenticated: true,
        }),
      ],
      [
        authLoginAsyncAction.failure,
        (state, { payload }) => ({
          ...state,
          login: {
            loading: false,
            error: payload,
          },
        }),
      ],
      [
        authLogoutAction,
        (state) => ({
          ...state,
          token: '',
          profile: {},
          authenticated: false,
        }),
      ],
    ]),
    initialState
  )
);
