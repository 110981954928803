import { createAsyncAction } from '../helpers';

export const getGamesAsync = createAsyncAction('GET_GAMES');
export const getGameDetailAsync = createAsyncAction(
  'GET_GAME_DETAIl'
);
export const createGameAsync = createAsyncAction('CREATE_GAME');
export const updateGameAsync = createAsyncAction('UPDATE_GAME');
export const uploadGameImageAsync = createAsyncAction(
  'UPLOAD_GAME_IMAGE'
);
