import { api, download } from '../helpers';

export const getList = (params, token) =>
  api('/users', 'GET', undefined, params, token);

export const getDetail = ({ id }, token) =>
  api(`/users/${id}`, 'GET', undefined, undefined, token);

export const getJoinedParticipants = ({ id }, token) =>
  api(`/users/${id}/tournaments`, 'GET', undefined, undefined, token);

export const updatePlayer = ({ id, ...payload }, token) =>
  api(`/users/${id}`, 'PUT', payload, undefined, token);

export const changePlayerStatus = ({ id, ...payload }, token) =>
  api(`/users/${id}/status`, 'PUT', payload, undefined, token);

export const uploadAvatar = ({ image }, token) => {
  const formData = new FormData();
  formData.append('images', image);
  return api(
    `/images_upload`,
    'POST',
    formData,
    undefined,
    token,
    ''
  );
};

export const getAuthAccounts = ({ id }, token) =>
  api(`/users/${id}/accounts`, 'GET', undefined, undefined, token);

export const changeForumRole = ({ id, forumRole }, token) =>
  api(
    `/users/${id}/forumRole`,
    'PUT',
    { forumRole },
    undefined,
    token
  );

export const exportPlayers = (params, token) =>
  download('/users/export', params, token);
