import { createAsyncAction } from '../helpers';

export const getPlayersAsync = createAsyncAction('GET_PLAYERS');
export const getPlayerDetailAsync = createAsyncAction(
  'GET_PLAYER_DETAIL'
);
export const updatePlayerAsync = createAsyncAction('UPDATE_PLAYER');
export const changePlayerStatusAsync = createAsyncAction(
  'CHANGE_PLAYER_STATUS'
);
export const getJoinedParticipantsAsync = createAsyncAction(
  'GET_JOINED_PARTICIPANTS'
);
export const uploadAvatarAsync = createAsyncAction('UPLOAD_AVATAR');
export const uploadIdCardUrlAsync = createAsyncAction(
  'UPLOAD_ID_CARD_URL'
);

export const getAuthAccountsAsync = createAsyncAction(
  'GET_AUTH_ACCOUNTS'
);
export const changeForumTitleAsync = createAsyncAction(
  'CHANGE_GENERAL_TITLE'
);
export const changeForumRoleAsync = createAsyncAction(
  'CHANGE_FORUM_ROLE'
);
export const downloadPlayersAsync = createAsyncAction(
  'DOWNLOAD_PLAYERS'
);
