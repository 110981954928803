import { createAsyncAction } from '../helpers';

export const getCommunityLeadersAsync = createAsyncAction(
  'GET_COMMUNITY_LEADERS'
);
export const getTournamentNamesAsync = createAsyncAction(
  'GET_TOURNAMENT_NAMES'
);
export const getCommunityLeaderDetailAsync = createAsyncAction(
  'GET_COMMUNITY_LEADER_DETAIL'
);
export const createCommunityLeaderAsync = createAsyncAction(
  'CREATE_COMMUNITY_LEADER'
);
export const updateCommunityLeaderStatusAsync = createAsyncAction(
  'UPDATE_COMMUNITY_LEADER_STATUS'
);
export const updateCommunityLeaderAsync = createAsyncAction(
  'UPDATE_COMMUNITY_LEADER'
);
export const deleteCommunityLeaderAsync = createAsyncAction(
  'DELETE_COMMUNITY_LEADER'
);
