import { api } from '../helpers';

export const getList = (params, token) =>
  api('/developer', 'GET', undefined, params, token);
export const getDetail = (id, token) =>
  api(`/developer/${id}`, 'GET', undefined, undefined, token);

export const createDeveloper = (payload, token) =>
  api('/developer/register', 'POST', payload, undefined, token);
export const changeStatus = ({ id, status }, token) =>
  api(
    `/developer/${id}/changeStatus`,
    'PUT',
    { status },
    undefined,
    token
  );
export const deleteDeveloper = ({ id }, token) =>
  api(`/developer/${id}`, 'DELETE', null, undefined, token);
export const generateApiKey = (params, token) =>
  api(`/developer/generate/api-key`, 'GET', null, undefined, token);
export const resendDeveloper = ({ id }, token) =>
  api(`/developer/${id}/reinvite`, 'POST', null, undefined, token);
export const getSubmission = (payload, token) =>
  api(
    `/developer/game/download/submissions`,
    'GET',
    null,
    undefined,
    token
  );
export const getSubmissionDetail = ({ id, ...payload }, token) =>
  api(
    `/developer/game/download/submissions/${id}`,
    'GET',
    null,
    undefined,
    token
  );
export const sendSubmission = ({ downloadId, ...payload }, token) =>
  api(
    `/developer/game/download/submissions/${downloadId}/review`,
    'PUT',
    payload,
    undefined,
    token
  );
export const sendSubmissionUnderReview = ({ downloadId }, token) =>
  api(
    `/developer/game/download/submissions/${downloadId}/under-review`,
    'PUT',
    null,
    undefined,
    token
  );
export const sendStoreSubmission = ({ id, ...payload }, token) =>
  api(
    `/developer/game/store/submissions/${id}/review`,
    'PUT',
    payload,
    undefined,
    token
  );
export const getStoreSubmission = (payload, token) =>
  api(
    `/developer/game/store/submissions`,
    'GET',
    null,
    undefined,
    token
  );
export const getStoreSubmissionDetail = ({ id, ...payload }, token) =>
  api(
    `/developer/game/store/submissions/${id}`,
    'GET',
    null,
    undefined,
    token
  );
export const sendStoreSubmissionUnderReview = ({ id }, token) =>
  api(
    `/developer/game/store/submission/${id}/under-review`,
    'PUT',
    null,
    undefined,
    token
  );
export const getItemSubmission = (payload, token) =>
  api(
    `/developer/game/item/submissions`,
    'GET',
    null,
    undefined,
    token
  );
export const getItemSubmissionDetail = ({ id, ...payload }, token) =>
  api(
    `/developer/game/item/submissions/${id}`,
    'GET',
    null,
    undefined,
    token
  );
export const sendItemSubmission = ({ id, ...payload }, token) =>
  api(
    `/developer/game/item/submissions/${id}/review`,
    'PUT',
    payload,
    undefined,
    token
  );
