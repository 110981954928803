import { createAsyncAction } from '../helpers';

export const getTournamentsAsync = createAsyncAction(
  'GET_TOURNAMENTS'
);

export const getTournamentDetailAsync = createAsyncAction(
  'GET_TOURNAMENT_DETAIL'
);

export const getFeaturedTournamentsAsync = createAsyncAction(
  'GET_FEATURED_TOURNAMENTS'
);

export const createTournamentAsync = createAsyncAction(
  'CREATE_TOURNAMENT'
);

export const updateTournamentAsync = createAsyncAction(
  'UPDATE_TOURNAMENT'
);

export const getCountryListAsync = createAsyncAction('GET_COUNTRIES');

export const uploadCoverImageAsync = createAsyncAction(
  'UPLOAD_COVER_IMAGE'
);

export const uploadFeaturedImagesAsync = createAsyncAction(
  'UPLOAD_FEATURE_IMAGE'
);
export const uploadRulesPdfAsync = createAsyncAction(
  'UPLOAD_PDF_RULES'
);

export const changeTournamentStatusAsync = createAsyncAction(
  'CHANGE_TOURNAMENT_STATUS'
);
export const changeTournamentFeaturedAsync = createAsyncAction(
  'CHANGE_TOURNAMENT_FEATURED'
);

export const removeTeamMemberAsync = createAsyncAction(
  'REMOVE_TEAM_MEMBER'
);

export const removeParticipantAsync = createAsyncAction(
  'REMOVE_PARTICIPANT'
);

export const removeTeamAsync = createAsyncAction('REMOVE_TEAM');

export const updateParticipantResultAsync = createAsyncAction(
  'UPDATE_PARTICIPANT_RESULT'
);
export const updateTeamResultAsync = createAsyncAction(
  'UPDATE_TEAM_RESULT'
);

export const getTournamentParticipantsAsync = createAsyncAction(
  'GET_TOURNAMENT_PARTICIPANTS'
);

export const getTournamentTeamsAsync = createAsyncAction(
  'GET_TOURNAMENT_TEAMS'
);

export const updateParticipantStatusAsync = createAsyncAction(
  'UPDATE_PARTICIPANT_STATUS'
);

export const updateTeamStatusAsync = createAsyncAction(
  'UPDATE_TEAM_STATUS'
);

export const getTournamentStatsAsync = createAsyncAction(
  'GET_TOURNAMENT_STATS'
);

export const downloadTournamentsAsync = createAsyncAction(
  'DOWNLOAD_TOURNAMENTS'
);

export const downloadTournamentsStatsAsync = createAsyncAction(
  'DOWNLOAD_TOURNAMENTS_STATISTIC'
);

export const downloadUpcomingSingleTourAsync = createAsyncAction(
  'DOWNLOAD_UPCOMING_SINGLE_TOUR'
);

export const downloadUpcomingTeamTourAsync = createAsyncAction(
  'DOWNLOAD_UPCOMING_TEAM_TOUR'
);

export const downloadJoinedTeamImagesAsync = createAsyncAction(
  'DOWNLOAD_JOINED_TEAM_IMAGES'
);

export const getAllTeamsAsync = createAsyncAction('GET_ALL_TEAMS');

export const getTournamentForBannerAsync = createAsyncAction(
  'GET_TOURNAMENT_FOR_BANNERS'
);

export const sendReminderAsync = createAsyncAction(
  'SEND_TOURNAMENTS_REMINDER'
);

export const getTournamentTeamListAsync = createAsyncAction(
  'GET_TOURNAMENT_TEAM_LIST'
);

export const getTournamentParticipantListAsync = createAsyncAction(
  'GET_TOURNAMENT_PARTICIPANT_LIST'
);

export const createBracketAsync = createAsyncAction('CREATE_BRACKET');

export const getBracketListAsync = createAsyncAction(
  'GET_BRACKET_LIST'
);

export const getBracketDetailAsync = createAsyncAction(
  'GET_BRACKET_DETAIL'
);

export const updateBracketAsync = createAsyncAction('UPDATE_BRACKET');

export const deleteBracketAsync = createAsyncAction('DELETE_BRACKET');

export const seedBracketTeamAsync = createAsyncAction(
  'SEED_BRACKET_TEAM'
);

export const updateTeamMemberInGameNameAsync = createAsyncAction(
  'UPDATE_IN_GAME_NAME_ASYNC'
);

export const updateTeamNameAsync = createAsyncAction(
  'UPDATE_TEAM_NAME_ASYNC'
);

export const updateParticipantInGameNameAsync = createAsyncAction(
  'UPDATE_PARTICIPANT_IN_GAME_NAME_ASYNC'
);

export const updateMatchScoreAsync = createAsyncAction(
  'UPDATE_MATCH_SCORE_ASYNC'
);

export const startBracketAsync = createAsyncAction(
  'START_BRACKET_ASYNC'
);

export const tournamentCheckInAsync = createAsyncAction(
  'CHECK_IN_ASYNC'
);

export const getMatchIssuesAsync = createAsyncAction(
  'GET_MATCH_ISSUES_ASYNC'
);

export const updateMatchIssueStatusAsync = createAsyncAction(
  'UPDATE_MATCH_ISSUE_STATUS_ASYNC'
);

export const getMatchDetailAsync = createAsyncAction(
  'GET_MATCH_DETAIL_ASYNC'
);

export const getStandingDetailAsync = createAsyncAction(
  'GET_ROUND_DETAIL_ASYNC'
);

export const submitFFABracketScoreAsync = createAsyncAction(
  'SUBMIT_FFA_BRACKET_SCORE_ASYNC'
);

export const uploadFFABracketScreenshotAsync = createAsyncAction(
  'UPLOAD_FFA_BRACKET_SCREENSHOT_ASYNC'
);

export const submitForfeitPlayerAsync = createAsyncAction(
  'SUBMIT_FORFEIT_PLAYER_ASYNC'
);

export const submitDoubleLossAsync = createAsyncAction(
  'SUBMIT_DOUBLE_LOSS_ASYNC'
);

export const submitDrawAsync = createAsyncAction('SUBMIT_DRAW_ASYNC');

export const submitDropParticipantAsync = createAsyncAction(
  'SUBMIT_DROP_PARTICIPANT_ASYNC'
);

export const generateNextSwissRoundAsync = createAsyncAction(
  'GENERATE_NEXT_SWISS_ROUND'
);

export const revertForfeitAsync = createAsyncAction('REVERT_FORFEIT');
