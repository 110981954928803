import { handleActions } from 'redux-actions';
import {
  getKlikStatusAsync,
  generateKlikCodeAsync,
} from './kliksAction';

const initialState = {
  status: {
    remaining: 0,
    used: 0,
  },
  fetch: {
    loading: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    changed: null,
    uploaded: false,
    error: '',
  },
};
export const kliksReducer = handleActions(
  new Map([
    [
      getKlikStatusAsync.success,
      (state, { payload }) => ({
        ...state,
        status: payload,
      }),
    ],
    [
      generateKlikCodeAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      generateKlikCodeAsync.success,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      generateKlikCodeAsync.failure,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
