import { createAsyncAction } from '../helpers';
export const getKlikStatusAsync = createAsyncAction(
  'GET_KLIK_STATUS'
);
export const generateKlikCodeAsync = createAsyncAction(
  'GENERATE_KLIK_CODE'
);
export const downloadKlikCodeAsync = createAsyncAction(
  'DOWNLOAD_KLIK_CODE'
);
