import { api } from '../helpers';

export const getMiniBanner = (params, token) =>
  api('/mini-banner', 'GET', undefined, undefined, token);
export const createMiniBanner = (payload, token) =>
  api('/mini-banner', 'POST', payload, undefined, token);
export const updateMiniBannerBackgroundImage = (
  { id, backgroundImage },
  token
) => {
  const formData = new FormData();
  formData.append('backgroundImage', backgroundImage);

  return api(
    `/mini-banner/${id}/background-image`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};
export const updateMiniBannerLogo = ({ id, logo }, token) => {
  const formData = new FormData();
  formData.append('logo', logo);

  return api(
    `/mini-banner/${id}/logo`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};
