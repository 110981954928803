import { createEpic } from '../helpers';
import {
  getExchangesAsync,
  refreshExchangesAsync,
  updateExchangesAsync,
} from './exchangesAction';

export const getExchangesEpic = createEpic(
  getExchangesAsync,
  'exchanges',
  'getList',
  true
);
export const refreshExchangesEpic = createEpic(
  refreshExchangesAsync,
  'exchanges',
  'refresh',
  true
);
export const updateExchangesEpic = createEpic(
  updateExchangesAsync,
  'exchanges',
  'update',
  true
);
