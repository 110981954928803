import * as auth from './auth/auth.service';
import * as articles from './articles/articles.service';
import * as games from './games/games.service';
import * as tournaments from './tournaments/tournaments.service';
import * as players from './players/players.service';
import * as admins from './admins/admins.service';
import * as dashboard from './dashboard/dashboard.service';
import * as banners from './banners/banners.service';
import * as stores from './stores/stores.service';
import * as storeBanners from './store-banners/store-banners.service';
import * as download from './download/download.service';
import * as payments from './payments/payments.service';
import * as kliks from './kliks/kliks.service';
import * as blizzard from './blizzard/blizzard.service';
import * as developers from './developers/developers.service';
import * as discount from './discount/discount.service';
import * as miniBanner from './mini-banner/mini-banner.service';
import * as exchanges from './exchanges/exchanges.service';
import * as publishGames from './publish-games/publish-games.service';
import * as communityLeaders from './community-leaders/community-leaders.service';

export default {
  auth,
  games,
  articles,
  players,
  tournaments,
  admins,
  dashboard,
  banners,
  stores,
  storeBanners,
  download,
  payments,
  kliks,
  blizzard,
  developers,
  discount,
  miniBanner,
  exchanges,
  publishGames,
  communityLeaders,
};

