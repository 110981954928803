import { api, download } from '../helpers';

export const getStatus = (params, token) =>
  api('/blizzard/stats', 'GET', undefined, undefined, token);

export const getList = (params, token) =>
  api('/blizzard', 'GET', undefined, params, token);

export const generate = ({ csv }, token) => {
  const formData = new FormData();
  formData.append('csv', csv);
  return api(
    `/blizzard/generate`,
    'POST',
    formData,
    undefined,
    token,
    ''
  );
};

export const downloadCodes = (params, token) =>
  download('/blizzard/export', params, token);

export const downloadReport = (params, token) =>
  download('/blizzard/report', params, token);
