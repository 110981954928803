import { api } from '../helpers';

export const getList = (params) =>
  api('/games', 'GET', undefined, params);
export const create = (payload, token) =>
  api('/games', 'POST', payload, undefined, token);
export const update = ({ id, ...payload }, token) =>
  api(`/games/${id}`, 'PUT', payload, undefined, token);
export const detail = (id, token) =>
  api(`/games/${id}`, 'GET', undefined, undefined, token);
export const imageUpload = ({ id, image }, token) => {
  const formData = new FormData();
  formData.append('image', image);
  return api(
    `/games/${id}/image`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};
