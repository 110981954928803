import { createAsyncAction } from '../helpers';

export const getDiscountAsync = createAsyncAction('GET_DISCOUNT');
export const getDiscountCodeAsync = createAsyncAction(
  'GET_DISCOUNT_CODE'
);
export const getDiscountCodeLogAsync = createAsyncAction(
  'GET_DISCOUNT_CODE_LOG'
);
export const getDiscountRewardedAsync = createAsyncAction(
  'GET_DISCOUNT_REWARD'
);

export const getDiscountDetailAsync = createAsyncAction(
  'GET_DISCOUNT_DETAIL'
);
export const getDiscountCodeDetailAsync = createAsyncAction(
  'GET_DISCOUNT_CODE_DETAIL'
);
export const getDiscountRewardedDetailAsync = createAsyncAction(
  'GET_DISCOUNT_REWARD_DETAIL'
);

export const createDiscountAsync = createAsyncAction(
  'CREATE_DISCOUNT'
);
export const createDiscountCodeAsync = createAsyncAction(
  'CREATE_DISCOUNT_CODE'
);
export const createDiscountRewardedAsync = createAsyncAction(
  'CREATE_DISCOUNT_REWARD'
);

export const updateDiscountAsync = createAsyncAction(
  'UPDATE_DISCOUNT'
);
export const updateDiscountCodeAsync = createAsyncAction(
  'UPDATE_DISCOUNT_CODE'
);
export const updateDiscountRewardedAsync = createAsyncAction(
  'UPDATE_DISCOUNT_REWARD'
);

export const getDiscountListAsync = createAsyncAction(
  'GET_LIST_DISCOUNT'
);
export const updateDiscountStatusAsync = createAsyncAction(
  'UPDATE_DISCOUNT_STATUS'
);

export const deleteDiscountRewardedAsync = createAsyncAction(
  'DELETE_DISCOUNT_REWARD'
);
export const deleteDiscountCodeAsync = createAsyncAction(
  'DELETE_DISCOUNT_CODE'
);
