import {
  createDownloadEpic,
  createEpic,
  createDownloadAsCSVEpic,
} from '../helpers';

import {
  getBlizzardStatusAsync,
  getBlizzardListAsync,
  importBlizzardCodeAsync,
  exportBlizzardCodeAsync,
  exportBlizzardReportAsync,
} from './blizzardAction';

export const getBlizzardStatusEpic = createEpic(
  getBlizzardStatusAsync,
  'blizzard',
  'getStatus',
  true
);

export const getBlizzardListEpic = createEpic(
  getBlizzardListAsync,
  'blizzard',
  'getList',
  true
);

export const importBlizzardCodeEpic = createEpic(
  importBlizzardCodeAsync,
  'blizzard',
  'generate',
  true
);

export const exportBlizzardCodeEpic = createDownloadAsCSVEpic(
  exportBlizzardCodeAsync,
  'blizzard',
  'downloadCodes',
  true,
  'blizzard-codes'
);

export const exportBlizzardReportEpic = createDownloadEpic(
  exportBlizzardReportAsync,
  'blizzard',
  'downloadReport',
  true,
  'blizzard-report'
);
