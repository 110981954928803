import { combineActions, handleActions } from 'redux-actions';
import {
  createStoreBannerAsync,
  getStoreBannerAsync,
  getStoreBannerDetailAsync,
  deleteStoreBannerAsync,
  updateStoreBannerAsync,
} from './storeBannersAction';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  detail: {},
  fetch: {
    loading: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    changed: null,
    uploaded: false,
    error: '',
  },
};
export const storeBannerReducer = handleActions(
  new Map([
    [
      getStoreBannerAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      getStoreBannerDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      deleteStoreBannerAsync.success,
      (state, { payload }) => ({
        ...state,
        changed: payload,
      }),
    ],
    [
      combineActions(
        createStoreBannerAsync.request,
        updateStoreBannerAsync.request,
        deleteStoreBannerAsync.request
      ),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        createStoreBannerAsync.success,
        updateStoreBannerAsync.success,
        deleteStoreBannerAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      combineActions(
        createStoreBannerAsync.failure,
        updateStoreBannerAsync.failure,
        deleteStoreBannerAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
