import { createAsyncAction } from '../helpers';
export const getBannersAsync = createAsyncAction('GET_BANNERS');
export const createBannerAsync = createAsyncAction('CREATE_BANNER');
export const changeBannerStatusAsync = createAsyncAction(
  'CHANGE_BANNER_STATUS'
);
export const getBannerDetailAsync = createAsyncAction(
  'GET_BANNER_DETAIL'
);
export const updateBannerAsync = createAsyncAction('UPDATE_BANNER');
export const UploadMastheadImageAsync = createAsyncAction(
  'UPLOAD_MASTHEAD_IMAGE'
);
export const deleteBannerAsync = createAsyncAction('DELETE_BANNER');
