import { combineActions, handleActions } from 'redux-actions';
import {
  changeBannerStatusAsync,
  createBannerAsync,
  getBannerDetailAsync,
  getBannersAsync,
  updateBannerAsync,
  UploadMastheadImageAsync,
  deleteBannerAsync,
} from './bannersAction';

const initialState = {
  list: {
    banner1: [],
    banner2: [],
    banner3: [],
    banner4: [],
  },
  detail: {},
  fetch: {
    loading: false,
    error: '',
  },

  action: {
    loading: false,
    success: false,
    changed: null,
    uploaded: false,
    error: '',
  },
};
export const bannerReducer = handleActions(
  new Map([
    [
      getBannersAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      getBannerDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      combineActions(
        createBannerAsync.request,
        updateBannerAsync.request,
        deleteBannerAsync.request,
        changeBannerStatusAsync.request
      ),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        createBannerAsync.success,
        deleteBannerAsync.success,
        updateBannerAsync.success,
        changeBannerStatusAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
          uploaded: false,
        },
      }),
    ],
    [
      combineActions(UploadMastheadImageAsync.success),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          uploaded: payload,
        },
      }),
    ],
  ]),
  initialState
);
