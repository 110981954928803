import { handleActions } from 'redux-actions';
import {
  // changeAdminStatusAsync,
  createAdminAsync,
  getAdminsAsync,
} from './adminsActions';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  fetch: {
    loading: false,
    error: '',
  },

  action: {
    loading: false,
    success: false,
    changed: null,
    error: '',
  },
};
export const adminReducer = handleActions(
  new Map([
    [
      getAdminsAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      createAdminAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      createAdminAsync.success,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          success: true,
        },
      }),
    ],
    // [
    // 	combineActions(changeAdminStatusAsync.success),
    // 	(state, payload) => ({
    // 		...state,
    // 		action: {
    // 			...initialState.action,
    // 			changed: payload,
    // 		},
    // 	}),
    // ],
  ]),
  initialState
);
