import { createEpic } from '../helpers';
import {
  changeBannerStatusAsync,
  createBannerAsync,
  getBannerDetailAsync,
  getBannersAsync,
  updateBannerAsync,
  UploadMastheadImageAsync,
  deleteBannerAsync,
} from './bannersAction';
export const getBannersEpic = createEpic(
  getBannersAsync,
  'banners',
  'getList',
  true
);
export const createBannerEpic = createEpic(
  createBannerAsync,
  'banners',
  'createBanner',
  true
);

export const getBannerDetail = createEpic(
  getBannerDetailAsync,
  'banners',
  'getBannerDetail',
  true
);

export const updateBannerEpic = createEpic(
  updateBannerAsync,
  'banners',
  'updateBanner',
  true
);

export const deleteBannerEpic = createEpic(
  deleteBannerAsync,
  'banners',
  'deleteBanner',
  true
);

export const changeBannerStatusEpic = createEpic(
  changeBannerStatusAsync,
  'banners',
  'changeBannerStatus',
  true
);

export const uploadBannerMastheadEpic = createEpic(
  UploadMastheadImageAsync,
  'banners',
  'uploadBannerImage',
  true
);
