import { createEpic } from '../helpers';
import {
  getStoreBannerAsync,
  createStoreBannerAsync,
  updateStoreBannerAsync,
  deleteStoreBannerAsync,
  getStoreBannerDetailAsync,
  uploadStoreBannerImagesAsync,
} from './storeBannersAction';

export const getStoreBannerEpic = createEpic(
  getStoreBannerAsync,
  'storeBanners',
  'getList',
  true
);
export const createStoreBannerEpic = createEpic(
  createStoreBannerAsync,
  'storeBanners',
  'create',
  true
);
export const updateStoreBannerEpic = createEpic(
  updateStoreBannerAsync,
  'storeBanners',
  'update',
  true
);
export const getStoreBannerDetailEpic = createEpic(
  getStoreBannerDetailAsync,
  'storeBanners',
  'detail',
  true
);

export const deleteStoreBannerEpic = createEpic(
  deleteStoreBannerAsync,
  'storeBanners',
  'deleteStoreBanner',
  true
);

export const uploadStoreImagesEpic = createEpic(
  uploadStoreBannerImagesAsync,
  'storeBanners',
  'imageUpload',
  true
);
