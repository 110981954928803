import { handleActions, combineActions } from 'redux-actions';

import {
  getArticleCategoriesAsync,
  createArticleCategoryAsync,
  deleteArticleCategoryAsync,
  createArticleAsync,
  getArticlesAsync,
  getArticleAsync,
  updateArticleAsync,
  changeArticleStatusAsync,
  getFeaturedArticlesAsync,
  changeArticleFeaturedAsync,
} from './articlesActions';

const initialState = {
  categories: {
    items: [],
  },

  articles: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },

  featuredArticlesCount: 0,

  detail: {},

  fetch: {
    loading: false,
    error: '',
  },

  action: {
    loading: false,
    success: false,
    error: '',
    changedFeatured: false,
  },
};

export const articlesReducer = handleActions(
  new Map([
    [
      getArticleCategoriesAsync.success,
      (state, { payload }) => ({
        ...state,
        categories: payload,
      }),
    ],
    [
      getArticlesAsync.success,
      (state, { payload }) => ({
        ...state,
        articles: payload,
      }),
    ],
    [
      getArticleAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      getFeaturedArticlesAsync.success,
      (state, { payload }) => ({
        ...state,
        featuredArticlesCount: payload.count,
      }),
    ],
    [
      changeArticleStatusAsync.success,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          updated: payload,
        },
      }),
    ],
    [
      changeArticleFeaturedAsync.success,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          changedFeatured: payload,
        },
      }),
    ],
    [
      combineActions(
        createArticleCategoryAsync.request,
        deleteArticleCategoryAsync.request,
        createArticleAsync.request,
        updateArticleAsync.request
      ),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        createArticleCategoryAsync.success,
        deleteArticleCategoryAsync.success,
        createArticleAsync.success,
        updateArticleAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      combineActions(
        createArticleCategoryAsync.failure,
        deleteArticleCategoryAsync.failure,
        createArticleAsync.failure,
        updateArticleAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
