import { createEpic } from '../helpers';
import {
  getArticleCategoriesAsync,
  createArticleCategoryAsync,
  deleteArticleCategoryAsync,
  createArticleAsync,
  getArticlesAsync,
  getArticleAsync,
  updateArticleAsync,
  uploadArticleCoverImageAsync,
  changeArticleStatusAsync,
  getFeaturedArticlesAsync,
  changeArticleFeaturedAsync,
} from './articlesActions';

export const getArticleCategoriesEpic = createEpic(
  getArticleCategoriesAsync,
  'articles',
  'getCategories',
  true
);

export const createArticleCategoryEpic = createEpic(
  createArticleCategoryAsync,
  'articles',
  'createCategory',
  true
);

export const deleteArticleCategoryEpic = createEpic(
  deleteArticleCategoryAsync,
  'articles',
  'deleteCategory',
  true
);

export const createArticleEpic = createEpic(
  createArticleAsync,
  'articles',
  'createArticle',
  true
);

export const getArticlesEpic = createEpic(
  getArticlesAsync,
  'articles',
  'getArticles',
  true
);

export const getArticleEpic = createEpic(
  getArticleAsync,
  'articles',
  'getArticle',
  true
);

export const updateArticleEpic = createEpic(
  updateArticleAsync,
  'articles',
  'updateArticle',
  true
);

export const uploadArticleCoverImageEpic = createEpic(
  uploadArticleCoverImageAsync,
  'articles',
  'uploadImage',
  true
);

export const changeArticleStatus = createEpic(
  changeArticleStatusAsync,
  'articles',
  'changeStatus',
  true
);

export const getFeaturedArticles = createEpic(
  getFeaturedArticlesAsync,
  'articles',
  'getFeaturedArticles',
  true
);

export const changeArticleFeatured = createEpic(
  changeArticleFeaturedAsync,
  'articles',
  'changeFeatured',
  true
);
