import { createEpic } from '../helpers';
import {
  getStoreAsync,
  getAllStoreAsync,
  createStoreAsync,
  getStoreDetailAsync,
  getStoreItemsAsync,
  getStoreCategoriesAsync,
  uploadStoreImagesAsync,
  deleteStoreAsync,
  updateStoreAsync,
  getDeveloperGamesAsync,
} from './storesAction';

export const getStoresEpic = createEpic(
  getStoreAsync,
  'stores',
  'getList',
  true
);
export const getAllStoreEpic = createEpic(
  getAllStoreAsync,
  'stores',
  'getAll',
  true
);
export const createStoresEpic = createEpic(
  createStoreAsync,
  'stores',
  'create',
  true
);
export const getStoresDetailEpic = createEpic(
  getStoreDetailAsync,
  'stores',
  'detail',
  true
);
export const updateStoreEpic = createEpic(
  updateStoreAsync,
  'stores',
  'update',
  true
);

export const getStoreItemsEpic = createEpic(
  getStoreItemsAsync,
  'stores',
  'getItems',
  true
);

export const getStoreCategoriesEpic = createEpic(
  getStoreCategoriesAsync,
  'stores',
  'getCategories',
  true
);

export const deleteStoreEpic = createEpic(
  deleteStoreAsync,
  'stores',
  'deleteStore',
  true
);

export const uploadStoreImagesEpic = createEpic(
  uploadStoreImagesAsync,
  'stores',
  'imageUpload',
  true
);

export const getDeveloperGamesEpic = createEpic(
  getDeveloperGamesAsync,
  'stores',
  'getDeveloperGames',
  true
);
