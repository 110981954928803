import { api } from '../helpers';

export const getList = (params) =>
  api('/download', 'GET', undefined, params);

export const create = (payload, token) =>
  api('/download', 'POST', payload, undefined, token);

export const logoUpload = ({ id, logo }, token) => {
  const formData = new FormData();
  formData.append('logo', logo);
  return api(
    `/download/${id}/logo`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};

export const mediaUpload = ({ id, media }, token) => {
  const formData = new FormData();
  media.forEach((v, i) => {
    formData.append('media_' + i, v.thumbnail ? v.thumbnail[0] : '');
  });
  return api(
    `/download/${id}/media`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};

export const detail = (id, token) =>
  api(`/download/${id}`, 'GET', undefined, undefined, token);

export const getCategories = (params, token) =>
  api('/download/categories', 'GET', undefined, params, token);

export const update = ({ id, ...payload }, token) =>
  api(`/download/${id}`, 'PUT', payload, undefined, token);

export const deleteDownload = (id, token) =>
  api('/download/' + id, 'DELETE', undefined, undefined, token);
