import { handleActions } from 'redux-actions';
import {
  getPaymentAsync,
  getPaymentStatusAsync,
} from './paymentsAction';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  status: {
    dayProfit: 0,
    monthProfit: 0,
    ottopayProfit: 0,
  },
  fetch: {
    loading: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    changed: null,
    uploaded: false,
    error: '',
  },
};
export const paymentsReducer = handleActions(
  new Map([
    [
      getPaymentAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      getPaymentStatusAsync.success,
      (state, { payload }) => ({
        ...state,
        status: payload,
      }),
    ],
  ]),
  initialState
);
