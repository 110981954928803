import {
  compose,
  combineReducers,
  createStore,
  applyMiddleware,
} from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';

import services from '../services';

import { authReducer } from './auth/authReducer';
import * as authEpics from './auth/authEpics';

import { articlesReducer } from './articles/articlesReducer';
import * as articlesEpics from './articles/articlesEpics';

import { gamesReducer } from './games/gamesReducer';
import * as gamesEpics from './games/gamesEpics';

import { tournamentReducer } from './tournaments/tournamentsReducer';
import * as tournamentsEpics from './tournaments/tournamentsEpics';

import { playersReducer } from './players/playersReducer';
import * as playersEpics from './players/playersEpics';

import { adminReducer } from './admins/adminsReducer';
import * as adminsEpics from './admins/adminsEpics';

import { dashboardReducer } from './dashboard/dashboardReducer';
import * as dashboardEpics from './dashboard/dashboardEpics';

import { bannerReducer } from './banners/bannersReducer';
import * as bannerEpic from './banners/bannersEpic';

import { miniBannerReducer } from './mini-banner/miniBannerReducer';
import * as miniBannerEpic from './mini-banner/miniBannerEpic';

import { storeReducer } from './stores/storesReducer';
import * as storesEpic from './stores/storesEpic';

import { storeBannerReducer } from './store-banners/storeBannersReducer';
import * as storeBannerEpic from './store-banners/storeBannersEpic';

import { downloadReducer } from './download/downloadReducer';
import * as downloadEpic from './download/downloadEpic';

import { paymentsReducer } from './payments/paymentsReducer';
import * as paymentsEpic from './payments/paymentsEpic';

import { kliksReducer } from './klik/kliksReducer';
import * as kliksEpic from './klik/kliksEpic';

import { blizzardReducer } from './blizzard/blizzardReducer';
import * as blizzardEpic from './blizzard/blizzardEpic';

import { developerReducer } from './developers/developersReducer';
import * as developerEpic from './developers/developersEpics';

import { discountReducer } from './discounts/discountReducer';
import * as discountEpic from './discounts/discountEpic';

import { exchangesReducer } from './exchanges/exchangesReducer';
import * as exchangesEpics from './exchanges/exchangesEpics';

import { publishGamesReducer } from './publish-games/publishGamesReducer';
import * as publishGamesEpics from './publish-games/publishGamesEpics';

import { communityLeaderReducer } from './community-leaders/communityLeadersReducer';
import * as communityLeadersEpics from './community-leaders/communityLeadersEpics';

let composeEnhancers;
if (process.env.NODE_ENV === 'production') {
  composeEnhancers = compose;
} else {
  composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const epicMiddleware = createEpicMiddleware({
  dependencies: services,
});

const rootReducer = combineReducers({
  auth: authReducer,
  games: gamesReducer,
  articles: articlesReducer,
  tournaments: tournamentReducer,
  players: playersReducer,
  admins: adminReducer,
  dashboard: dashboardReducer,
  banners: bannerReducer,
  stores: storeReducer,
  storeBanners: storeBannerReducer,
  downloads: downloadReducer,
  payments: paymentsReducer,
  kliks: kliksReducer,
  blizzard: blizzardReducer,
  developers: developerReducer,
  discounts: discountReducer,
  miniBanner: miniBannerReducer,
  exchanges: exchangesReducer,
  publishGames: publishGamesReducer,
  communityLeaders: communityLeaderReducer,
});

const rootEpic = combineEpics(
  ...Object.values(authEpics),
  ...Object.values(articlesEpics),
  ...Object.values(gamesEpics),
  ...Object.values(tournamentsEpics),
  ...Object.values(playersEpics),
  ...Object.values(adminsEpics),
  ...Object.values(dashboardEpics),
  ...Object.values(bannerEpic),
  ...Object.values(storesEpic),
  ...Object.values(storeBannerEpic),
  ...Object.values(downloadEpic),
  ...Object.values(paymentsEpic),
  ...Object.values(kliksEpic),
  ...Object.values(blizzardEpic),
  ...Object.values(developerEpic),
  ...Object.values(discountEpic),
  ...Object.values(miniBannerEpic),
  ...Object.values(exchangesEpics),
  ...Object.values(publishGamesEpics),
  ...Object.values(communityLeadersEpics)
);

export const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'production'
    ? composeEnhancers(applyMiddleware(epicMiddleware))
    : composeEnhancers(applyMiddleware(logger, epicMiddleware))
);
epicMiddleware.run(rootEpic);
export const persistor = persistStore(store);

//epicMiddleware.run(rootEpic);
