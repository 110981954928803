import { createAsyncAction } from '../helpers';
export const getDevelopersAsync = createAsyncAction('GET_DEVELOPERS');
export const getDeveloperDetailAsync = createAsyncAction(
  'GET_DEVELOPERS_DETAIL'
);
export const createDeveloperAsync = createAsyncAction(
  'CREATE_DEVELOPER'
);
export const changeDeveloperStatusAsync = createAsyncAction(
  'CHANGE_DEVELOPER_STATUS'
);
export const deleteDeveloperAsync = createAsyncAction(
  'DELETE_DEVELOPER'
);
export const generateApiKeyAsync = createAsyncAction(
  'GENERATE_API_KEY'
);
export const resendDeveloperAsync = createAsyncAction(
  'RESEND_DEVELOPER'
);
export const getSubmissionAsync = createAsyncAction(
  'GET_DEVELOPERS_SUBMISSION'
);
export const sendSubmissionAsync = createAsyncAction(
  'SEND_DEVELOPERS_SUBMISSION'
);
export const sendSubmissionUnderReviewAsync = createAsyncAction(
  'SEND_DEVELOPERS_SUBMISSION_UNDER_REVIEW'
);
export const getSubmissionDetailAsync = createAsyncAction(
  'GET_SUBMISSION_DETAIL'
);
export const getStoreSubmissionAsync = createAsyncAction(
  'GET_STORE_SUBMISSION'
);
export const getStoreSubmissionDetailAsync = createAsyncAction(
  'GET_STORE_SUBMISSION_DETAIL'
);
export const sendStoreSubmissionAsync = createAsyncAction(
  'SEND_STORE_SUBMISSION'
);
export const sendStoreSubmissionUnderReviewAsync = createAsyncAction(
  'SEND_DEVELOPERS_STORE_SUBMISSION_UNDER_REVIEW'
);
export const getItemSubmissionAsync = createAsyncAction(
  'GET_ITEM_SUBMISSION'
);
export const getItemSubmissionDetailAsync = createAsyncAction(
  'GET_ITEM_SUBMISSION_DETAIL'
);
export const sendItemSubmissionAsync = createAsyncAction(
  'SEND_ITEM_SUBMISSION'
);
