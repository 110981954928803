import { combineActions, handleActions } from 'redux-actions';
import {
  getDownloadAsync,
  createDownloadAsync,
  updateDownloadAsync,
  deleteDownloadAsync,
  getDownloadDetailAsync,
  getDownloadCategoriesAsync,
} from './downloadAction';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  categories: [],
  detail: {},
  fetch: {
    loading: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    changed: null,
    uploaded: false,
    error: '',
  },
};
export const downloadReducer = handleActions(
  new Map([
    [
      getDownloadCategoriesAsync.success,
      (state, { payload }) => ({
        ...state,
        categories: payload,
      }),
    ],
    [
      getDownloadAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      getDownloadDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      deleteDownloadAsync.success,
      (state, { payload }) => ({
        ...state,
        changed: payload,
      }),
    ],
    [
      combineActions(
        createDownloadAsync.request,
        updateDownloadAsync.request,
        deleteDownloadAsync.request
      ),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        createDownloadAsync.success,
        updateDownloadAsync.success,
        deleteDownloadAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      combineActions(
        createDownloadAsync.failure,
        updateDownloadAsync.failure,
        deleteDownloadAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
