import { api } from '../helpers';

export const getList = (params, token) =>
  api('/auth/admins', 'GET', undefined, params, token);

export const createAdmin = (payload, token) =>
  api('/admins/register', 'POST', payload, undefined, token);

export const changeForumTitle = ({ id, forumTitle }, token) =>
  api(
    `/admins/${id}/changeForumTitle`,
    'PUT',
    { forumTitle },
    undefined,
    token
  );
export const changeAdminStatus = ({ id, status }, token) =>
  api(
    `/admins/${id}/changeStatus`,
    'PUT',
    { status },
    undefined,
    token
  );
