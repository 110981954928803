import { handleActions, combineActions } from 'redux-actions';
import {
  getDevelopersAsync,
  createDeveloperAsync,
  changeDeveloperStatusAsync,
  deleteDeveloperAsync,
  generateApiKeyAsync,
  resendDeveloperAsync,
  getSubmissionAsync,
  sendSubmissionAsync,
  getSubmissionDetailAsync,
  getStoreSubmissionAsync,
  getStoreSubmissionDetailAsync,
  sendStoreSubmissionAsync,
  getDeveloperDetailAsync,
  sendSubmissionUnderReviewAsync,
  sendStoreSubmissionUnderReviewAsync,
  getItemSubmissionAsync,
  getItemSubmissionDetailAsync,
  sendItemSubmissionAsync,
} from './developersActions';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  downloadSubmissions: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  storeSubmissions: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  itemSubmissions: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  detail: {},
  submissionDetail: {},
  apiKey: '',
  action: {
    loading: false,
    success: false,
    changed: null,
    error: '',
  },
};
export const developerReducer = handleActions(
  new Map([
    [
      getStoreSubmissionDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        submissionDetail: payload,
      }),
    ],
    [
      getStoreSubmissionAsync.success,
      (state, { payload }) => ({
        ...state,
        storeSubmissions: payload,
      }),
    ],
    [
      getSubmissionDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        submissionDetail: payload,
      }),
    ],
    [
      getSubmissionAsync.success,
      (state, { payload }) => ({
        ...state,
        downloadSubmissions: payload,
      }),
    ],
    [
      getItemSubmissionAsync.success,
      (state, { payload }) => ({
        ...state,
        itemSubmissions: payload,
      }),
    ],
    [
      getItemSubmissionDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        submissionDetail: payload,
      }),
    ],
    [
      getDevelopersAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      createDeveloperAsync.request,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      createDeveloperAsync.success,
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          success: true,
        },
      }),
    ],
    [
      createDeveloperAsync.failure,
      (state, payload) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],
    [
      generateApiKeyAsync.success,
      (state, { payload }) => ({
        ...state,
        apiKey: payload,
      }),
    ],
    [
      getDeveloperDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      combineActions(
        sendSubmissionAsync.request,
        sendSubmissionUnderReviewAsync.request,
        resendDeveloperAsync.request,
        changeDeveloperStatusAsync.request,
        deleteDeveloperAsync.request,
        sendStoreSubmissionAsync.request,
        sendStoreSubmissionUnderReviewAsync.request,
        sendItemSubmissionAsync.request
      ),
      (state) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: true,
        },
      }),
    ],
    [
      combineActions(
        sendSubmissionAsync.success,
        sendSubmissionUnderReviewAsync.success,
        resendDeveloperAsync.success,
        changeDeveloperStatusAsync.success,
        deleteDeveloperAsync.success,
        sendStoreSubmissionAsync.success,
        sendStoreSubmissionUnderReviewAsync.success,
        sendItemSubmissionAsync.success
      ),
      (state, payload) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: false,
          changed: payload,
        },
      }),
    ],
    [
      combineActions(
        sendSubmissionAsync.failure,
        sendSubmissionUnderReviewAsync.failure,
        resendDeveloperAsync.failure,
        changeDeveloperStatusAsync.failure,
        deleteDeveloperAsync.failure,
        sendStoreSubmissionAsync.failure,
        sendStoreSubmissionUnderReviewAsync.failure,
        sendItemSubmissionAsync.failure
      ),
      (state, payload) => ({
        ...state,
        action: {
          ...initialState.action,
          loading: false,
          error: payload,
        },
      }),
    ],
  ]),
  initialState
);
