import { createEpic } from '../helpers';
import {
  getPaymentAsync,
  getPaymentStatusAsync,
} from './paymentsAction';

export const getPaymentEpic = createEpic(
  getPaymentAsync,
  'payments',
  'getList',
  true
);

export const getPaymentStatusEpic = createEpic(
  getPaymentStatusAsync,
  'payments',
  'getStatus',
  true
);
