import { createAsyncAction } from '../helpers';

export const getDownloadAsync = createAsyncAction('GET_DOWNLOAD');
export const createDownloadAsync = createAsyncAction(
  'CREATE_DOWNLOAD'
);
export const updateDownloadAsync = createAsyncAction(
  'UPDATE_DOWNLOAD'
);
export const deleteDownloadAsync = createAsyncAction(
  'DELETE_DOWNLOAD'
);
export const getDownloadDetailAsync = createAsyncAction(
  'GET_DOWNLOAD_DETAIL'
);
export const getDownloadCategoriesAsync = createAsyncAction(
  'GET_DOWNLOAD_CATEGORIES'
);
export const uploadDownloadMediaAsync = createAsyncAction(
  'UPLOAD_DOWNLOAD_MEDIA'
);
export const uploadDownloadLogoAsync = createAsyncAction(
  'UPLOAD_DOWNLOAD_LOGO'
);
