import { api } from '../helpers';

export const getList = (params, token) =>
  api('/discount/list', 'GET', undefined, params, token);
export const getCodeList = (params, token) =>
  api('/discount/redeemcode/list', 'GET', undefined, params, token);
export const getCodeLogList = ({ id, ...params }, token) =>
  api(
    `/discount/redeemcode/${id}/log`,
    'GET',
    undefined,
    params,
    token
  );
export const getRewardedList = (params, token) =>
  api('/discount/user/list', 'GET', undefined, params, token);
export const getDetail = (id, token) =>
  api(`/discount/${id}/detail`, 'GET', undefined, undefined, token);
export const getCodeDetail = (id, token) =>
  api(
    `/discount/redeemcode/${id}/detail`,
    'GET',
    undefined,
    undefined,
    token
  );
export const getRewardDetail = (id, token) =>
  api(`/discount/user/${id}`, 'GET', undefined, undefined, token);
export const update = ({ id, ...payload }, token) =>
  api(`/discount/${id}/update`, 'PUT', payload, undefined, token);
export const updateCode = ({ id, ...payload }, token) =>
  api(
    `/discount/redeemcode/${id}/update`,
    'PUT',
    payload,
    undefined,
    token
  );
export const updateReward = ({ id, ...payload }, token) =>
  api(
    `/discount/user/${id}/update`,
    'PUT',
    payload,
    undefined,
    token
  );
export const create = (payload, token) =>
  api(`/discount/create`, 'POST', payload, undefined, token);
export const createCode = (payload, token) =>
  api(
    `/discount/redeemcode/create`,
    'POST',
    payload,
    undefined,
    token
  );
export const createReward = (payload, token) =>
  api(`/discount/user/create`, 'POST', payload, undefined, token);

export const getDiscounts = (params, token) =>
  api(`/discount`, 'GET', undefined, undefined, token);
export const updateDiscountStatus = ({ id, ...payload }, token) =>
  api(
    `/discount/update/${id}/status`,
    'PUT',
    payload,
    undefined,
    token
  );
export const deleteReward = (id, token) =>
  api(
    `/discount/user/${id}/delete`,
    'DELETE',
    undefined,
    undefined,
    token
  );
export const deleteCode = (id, token) =>
  api(
    `/discount/redeemcode/${id}/delete`,
    'DELETE',
    undefined,
    undefined,
    token
  );
