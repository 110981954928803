import { api } from '../helpers';

export const getList = (params) =>
  api('/publish-games', 'GET', undefined, params);
export const create = (payload, token) =>
  api('/publish-games', 'POST', payload, undefined, token);
export const update = ({ id, ...payload }, token) =>
  api(`/publish-games/${id}`, 'PUT', payload, undefined, token);
export const detail = (id, token) =>
  api(`/publish-games/${id}`, 'GET', undefined, undefined, token);
export const deleteGame = (id, token) =>
  api(`/publish-games/${id}`, 'DELETE', undefined, undefined, token);
export const getPlayers = ({ id, ...params }, token) =>
  api(
    `/publish-games/${id}/players`,
    'GET',
    undefined,
    params,
    token
  );
