import { createEpic } from '../helpers';
import {
  createAdminAsync,
  getAdminsAsync,
  changeForumTitleAsync,
  changeAdminStatusAsync,
} from './adminsActions';
export const getAdminsEpic = createEpic(
  getAdminsAsync,
  'admins',
  'getList',
  true
);
export const createAdminEpic = createEpic(
  createAdminAsync,
  'admins',
  'createAdmin',
  true
);
export const changeForumTitle = createEpic(
  changeForumTitleAsync,
  'admins',
  'changeForumTitle',
  true
);

export const changeAdminStatus = createEpic(
  changeAdminStatusAsync,
  'admins',
  'changeAdminStatus',
  true
);
