import { api } from '../helpers';

export const getList = (params) =>
  api('/store-games', 'GET', undefined, params);

export const getAll = (params, token) =>
  api('/store-games/list', 'GET', undefined, undefined, token);

export const create = (payload, token) =>
  api('/store-games', 'POST', payload, undefined, token);

export const update = ({ id, ...payload }, token) =>
  api(`/store-games/${id}`, 'PUT', payload, undefined, token);

export const detail = (id, token) =>
  api(`/store-games/${id}`, 'GET', undefined, undefined, token);

export const getCategories = (params, token) =>
  api('/store-games/categories', 'GET', undefined, params, token);

export const getItems = (params, token) =>
  api('/store-games/items', 'GET', undefined, params, token);

export const getDeveloperGames = (params, token) =>
  api('/developer/game/list', 'GET', undefined, params, token);

export const getVouchers = (params, token) =>
  api('/store-games/vouchers', 'GET', undefined, params, token);

export const getVoucherStatus = (params, token) =>
  api(
    '/store-games/vouchers/status',
    'GET',
    undefined,
    params,
    token
  );

export const imageUpload = ({ id, image, thumbnail }, token) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('thumbnail', thumbnail);
  return api(
    `/store-games/${id}/image`,
    'PUT',
    formData,
    undefined,
    token,
    ''
  );
};

export const deleteStore = (id, token) =>
  api('/store-games/' + id, 'DELETE', undefined, undefined, token);
