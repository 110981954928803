import { combineActions, handleActions } from 'redux-actions';
import {
  getPlayersAsync,
  getPlayerDetailAsync,
  getJoinedParticipantsAsync,
  updatePlayerAsync,
  uploadAvatarAsync,
  getAuthAccountsAsync,
  uploadIdCardUrlAsync,
  changeForumRoleAsync,
  changeForumTitleAsync,
} from './playersActions';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },

  detail: {},
  tournaments: {
    items: [],
  },
  accounts: [],
  fetch: {
    loading: false,
    error: '',
  },

  action: {
    loading: false,
    success: false,
    changed: false,
    uploaded: false,
    idCardUploaded: false,
    error: '',
  },
};

export const playersReducer = handleActions(
  new Map([
    [
      getPlayersAsync.success,
      (state, { payload }) => ({ ...state, list: payload }),
    ],
    [
      getPlayerDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      getJoinedParticipantsAsync.success,
      (state, { payload }) => ({
        ...state,
        tournaments: payload,
      }),
    ],
    [
      updatePlayerAsync.success,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      uploadAvatarAsync.success,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          uploaded: payload,
        },
      }),
    ],
    [
      uploadIdCardUrlAsync.success,
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          idCardUploaded: payload,
        },
      }),
    ],
    [
      getAuthAccountsAsync.success,
      (state, { payload }) => ({
        ...state,
        accounts: payload,
      }),
    ],
    [
      combineActions(
        changeForumRoleAsync.success,
        changeForumTitleAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          changed: payload,
        },
      }),
    ],
  ]),
  initialState
);
