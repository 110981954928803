import { createAsyncAction } from '../helpers';

export const getArticleCategoriesAsync = createAsyncAction(
  'GET_ARTICLE_CATEGORIES'
);

export const createArticleCategoryAsync = createAsyncAction(
  'CREATE_ARTICLE_CATEGORY'
);

export const deleteArticleCategoryAsync = createAsyncAction(
  'DELETE_ARTICLE_CATEGORY'
);

export const createArticleAsync = createAsyncAction('CREATE_ARTICLE');
export const updateArticleAsync = createAsyncAction('UPDATE_ARTICLE');
export const uploadArticleCoverImageAsync = createAsyncAction(
  'UPLOAD_ARTICLE_COVER_IMAGE'
);

export const getArticlesAsync = createAsyncAction('GET_ARTICLES');

export const getFeaturedArticlesAsync = createAsyncAction(
  'GET_FEATURED_ARTICLES'
);
export const changeArticleFeaturedAsync = createAsyncAction(
  'CHANGE_ARTICLE_FEATURED'
);

export const getArticleAsync = createAsyncAction('GET_ARTICLE');
export const changeArticleStatusAsync = createAsyncAction(
  'CHANGE_ARTICLE_STATUS'
);
